import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const ChaptersMainPage = () => {
  const [selectedStandard, setselectedStandard] = useState("");
  const [selectedSubject, setselectedSubject] = useState(null);
  const [selectedElement, setselectedElement] = useState(null);
  const [subjects, setsubjects] = useState([]);
  const [ChaptersName, setChaptersName] = useState([]);
  const [SubjectElementData, setSubjectElementData] = useState([]);
  const [LoadAgain, setLoadAgain] = useState(false);

  useEffect(() => {
    FetchSubjectElementData();
  }, [selectedSubject]);

  const handleStandard = async (value) => {
    await FetchSubjects(value);
    setselectedStandard(value);
  };

  // Subject List
  const FetchSubjects = async (value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/trending/subjects`,
        {
          params: {
            standard_id: value,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setsubjects(response.data);
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  // Fetch Chapter Data
  const FetchSubjectElementData = async (value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/element-menu`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setSubjectElementData(response.data.elements);
      }
    } catch (error) {
      console.error("Error fetching chapter:", error);
    }
  };

  const linkElement = async () => {
    setLoadAgain(false);
    if (!selectedElement || !selectedStandard || !selectedSubject) {
      toast.error("All fields are mandatory", {
        position: "top-center",
      });
      return;
    }

    try {
      const newChapterData = {
        element_id: parseInt(selectedElement),
        subject_id: parseInt(selectedSubject),
      };

      const addChapterResponse = await axios.post(
        `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/add/subject/element-menu`,
        newChapterData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (addChapterResponse.data) {
        toast.success(addChapterResponse.data.message, {
          position: "top-center",
        });
        setLoadAgain(true);
        setselectedStandard("");
        setselectedSubject("");
        setselectedElement("");
      }
    } catch (error) {
      console.error("Error adding chapter:", error);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Link Subject wise element</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item">Subject element</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body">
              <form className="row g-3" encType="multipart/form-data">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Standard:</label>
                  <select
                    className="form-control mt-1"
                    value={selectedStandard}
                    onChange={(e) => handleStandard(e.target.value)}
                  >
                    <option selected value="">
                      Select Standard
                    </option>
                    <option value="1">ધોરણ 12</option>
                    <option value="2">ધોરણ 11</option>
                    <option value="3">ધોરણ 10</option>
                    <option value="4">ધોરણ 9</option>
                    <option value="5">ધોરણ 8</option>
                    <option value="6">ધોરણ 7</option>
                    <option value="7">ધોરણ 6</option>
                    <option value="8">ધોરણ 5</option>
                    <option value="9">ધોરણ 4</option>
                    <option value="10">ધોરણ 3</option>
                    <option value="11">ધોરણ 2</option>
                    <option value="12">ધોરણ 1</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Subjects:</label>

                  <select
                    className="form-control mt-1"
                    value={selectedSubject}
                    onChange={(e) => setselectedSubject(e.target.value)}
                  >
                    <option selected>Select subject</option>
                    {subjects.map((subject, index) => (
                      <option key={index} value={subject.id}>
                        {subject.title}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Element:</label>

                  <select
                    className="form-control mt-1"
                    value={selectedElement}
                    onChange={(e) => setselectedElement(e.target.value)}
                  >
                    <option selected>Select Element</option>
                    {SubjectElementData.map((subject, index) => (
                      <>
                        <option key={index} value={subject.id}>
                          {subject.title}
                        </option>
                      </>
                    ))}
                  </select>
                </div>

                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="insert"
                    onClick={linkElement}
                  >
                    Link Element
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
      {/* End #main */}
    </>
  );
};

export default ChaptersMainPage;
